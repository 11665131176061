<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto">
      <h1
        class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
      >
        Older Tickets
      </h1>

      <div class="mb-4">
        <!-- component -->
        <div id="" class="">
          <table id="" class="border-collapse w-full text-sm">
            <thead>
              <tr>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  CSV File
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Created Date
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-if="!items"
                class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                  colspan="5"
                >
                  Please wait...
                </td>
              </tr>
              <tr
                v-for="(item, i) in items"
                :key="i"
                class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  <a
                    class="cursor-pointer text-blue-600 underline"
                    :href="item.filename"
                    target="_"
                    download
                    >Download CSV</a
                  >
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                >
                  {{ new Date(item.createdat).toLocaleDateString() }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "OldTickets",
  data() {
    return {
      items: null
    };
  },
  created() {
    this.get();
  },
  methods: {
    async get() {
      try {
        const res = await this.$axios.get(`${this.$api}/ticket/csv-tickets`);
        this.items = res.data.data;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
